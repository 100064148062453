<template>
  <div class="pt-10">
    <v-row
      ><v-icon class="mx-auto" size="160" color="#b1b1b1"
        >{{ icon }}
      </v-icon></v-row
    >
    <v-row
      ><p
        class="mx-auto"
        style="
          font-size: 30px;
          color: #7f7f7f;
          font-weight: 500;
          letter-spacing: 0px;
        "
      >
        {{ title }}
      </p></v-row
    >
    <v-row
      ><p
        class="mx-auto"
        style="
          font-size: 16px;
          color: #7f7f7f;
          letter-spacing: 0px;
          text-align: center;
        "
      >
        {{ msg }}
      </p></v-row
    >
    <v-row
      ><p
        class="mx-auto"
        style="
          font-size: 16px;
          color: #7f7f7f;
          letter-spacing: 1px;
          text-align: center;
        "
      >
        {{ secondMsg }}
      </p></v-row
    >
  </div>
</template>

<script>
export default {
  name: "ContentLoader",
  props: ["title", "msg", "secondMsg", "icon"],
};
</script>